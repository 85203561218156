@import url('https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
//font-family: 'Hind', sans-serif;

//-e-global-color-primary: #6EC1E4;
//--e-global-color-secondary: #54595F;
//--e-global-color-text: #7A7A7A;
//--e-global-color-accent: #61CE70;
//--e-global-color-53f65d0: #384581;
//--e-global-color-8e06313: #4D4B4C;
//--e-global-color-5889d0e: #8E8E8E;

$black: #000;
$white: #fff;
$off-white: #fefefe;
$grey: #666;
$grey-dark: #222;
$grey-light: #ccc;
$grey-light-30: lighten($grey, 30%);
$dirt-gray: #23241f;

$success: #08fe00;
//$error: #c10000;
$remove: #c66865;
$copy: #6fc665;
$edit: #65aac6;

$input-border-color: #c5c5c5;

$overlay-color: rgba(0, 0, 0, 0.5);
$empty-stage-color: rgba(255, 255, 255, 0.25);
$hidden-field-bgcolor: rgba(255, 255, 255, 0.6);
$input-box-shadow-color: rgba(0, 0, 0, 0.075);

$primary: #6ec1e4;

$grid-gutter-width: 17px;

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/root';
// @import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
// @import '~bootstrap/scss/images';
// @import '~bootstrap/scss/code';
@import '~bootstrap/scss/grid';
// @import '~bootstrap/scss/tables';
// @import '~bootstrap/scss/forms';
// @import '~bootstrap/scss/buttons';
// @import '~bootstrap/scss/transitions';
// @import '~bootstrap/scss/dropdown';
// @import '~bootstrap/scss/button-group';
// @import '~bootstrap/scss/input-group';
// @import '~bootstrap/scss/custom-forms';
// //@import "~bootstrap/scss/navbar";
// @import '~bootstrap/scss/card';
// //@import "~bootstrap/scss/breadcrumb";
// //@import "~bootstrap/scss/pagination";
// @import '~bootstrap/scss/badge';
// @import '~bootstrap/scss/jumbotron';
// @import '~bootstrap/scss/alert';
// @import '~bootstrap/scss/progress';
// @import '~bootstrap/scss/media';
// @import '~bootstrap/scss/list-group';
// @import '~bootstrap/scss/close';
// @import '~bootstrap/scss/modal';
// @import '~bootstrap/scss/tooltip';
// @import '~bootstrap/scss/popover';
// @import '~bootstrap/scss/carousel';
@import '~bootstrap/scss/utilities';
// @import '~bootstrap/scss/print';

@import '~antd/dist/antd.css';
@import '~antd-button-color/dist/css/style.css';

@import './bhvp';
