@import './bh/loader.scss';
@import './box-shadow.scss';
@import './bh/default.scss';
@import './bh/mixins.scss';
@import './bh/calendar.scss';
@import './bh/elements.scss';

* {
  font-family: 'Hind', sans-serif;
}

html,
body,
#root,
#root > div {
  height: 100%;
}

.ant-page-header {
  padding: 0px;
}

.ant-page-header-heading-title {
  font-size: 22px;
}

.ant-page-header-heading-sub-title {
  font-size: 18px;
}

@keyframes fade {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes fade {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  to {
    opacity: 1;
  }
}

.blinking {
  animation: fade 1000ms infinite;
  -webkit-animation: fade 1000ms infinite;
}
.justify-between {
  justify-content: space-between;
}
.items-center {
  align-items: center;
}
.flex-row {
  flex-direction: row;
}
.flex {
  display: flex;
}
.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.border-none {
  border-style: none;
}
.w-full {
  width: 100%;
}

.ant-typography-link {
  color: #1677ff!important;
}