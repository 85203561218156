.mouse-click {
  cursor: pointer;
}

.btn {
  font-weight: 600;
}

.ham {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: transform 400ms;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hamRotate.active {
  transform: rotate(45deg);
}
.hamRotate180.active {
  transform: rotate(180deg);
}
.line {
  fill: none;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke: #2b3f86;
  stroke-width: 5.5;
  stroke-linecap: round;
}
.ham2 .top {
  stroke-dasharray: 40 121;
}
.ham2 .bottom {
  stroke-dasharray: 40 121;
}
.ham2.active .top {
  stroke-dashoffset: -102px;
}
.ham2.active .bottom {
  stroke-dashoffset: -102px;
}

#wrapper {
  display: flex;
  min-height: 100%;

  #sidebar {
    background-color: #fff;
    width: 100px;
    // height: 100%;
    transition: all 300ms;
    z-index: 1;
    box-shadow: 5px 0 5px 0 rgba($color: #000000, $alpha: 0.14);

    &.active {
      width: 300px;

      .header {
        animation-name: change-content;
        animation-delay: 1s;
        animation-fill-mode: forwards;

        span {
          display: block;
        }
      }
    }

    .header {
      // background-color: #ccc;
      height: 60px;
      border-bottom: 1px solid #bbb;

      white-space: nowrap;
      overflow: hidden;

      display: flex;
      align-items: center;
      justify-content: space-around;

      .logo {
        height: 100%;
        width: auto;
      }
      span {
        display: none;
        font-family: 'Bebas Neue', cursive;
        color: #2b3f86;
        font-size: 28px;
      }
      svg {
        width: 50px;
      }
    }

    .menu {
      .ant-menu-inline-collapsed {
        width: 100px;
      }

      .ant-menu {
        .ant-menu-item {
          display: flex;
          align-items: center;

          .title {
            font-size: 16px;
          }
        }
      }
    }
  }

  #topbar {
    background-color: #aaa;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    box-shadow: -8px 15px 10px -9px rgba($color: #000000, $alpha: 0.3);
  }

  #content {
    background-color: #f0f2f5;
    width: 100%;
    .container {
      padding: 20px 30px;
      max-width: 100%;
    }
  }
}

.dev {
  background-color: #ede49f!important;
}
